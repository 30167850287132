import React, { useEffect, useState } from 'react';
import './checkout.css';
import axios from 'axios';
import { apiurl } from '../../../urls/url';
import { Cookies, CookiesProvider, useCookies } from 'react-cookie';
import { jwtDecode } from 'jwt-decode';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

// import GetCurrentAddress from '../../comcomponents/UserCountry';
export default function Checkout(props) {

    const navigate = useNavigate()
    const [cartdata, setcartdata] = useState();
    const [carttotaldata, setcarttotaldata] = useState();
    const [coupon, setcoupon] = useState();
    const [checksameadd, setchecksameadd] = useState(false);
    const [orderid, setorderid] = useState();
    const userid = localStorage.getItem('token')
    const [shipping, setshipping] = useState('free');
    const [add, setAdd] = useState('');
    const [states, setState] = useState([])
    const [cities, setcities] = useState([])
    const [discount, setdiscount] = useState("")
    const [o_id,setO_id]=useState("")
    var id;
    if (userid) {
        id = jwtDecode(userid)
    }
    const [formData, setFormData] = useState({
        firstName: id?.full_name,
        lastName: '',
        country: "India",
        address: '',
        city: "",
        state: '',
        pincode: '',
        phoneNo: '',
        email: id?.email,
    });
    // useEffect(() => {
    //     const address=JSON.parse(localStorage.getItem('location'))
    //     setAdd(address)
    //     setFormData({
    //         city:add.city,
    //         country:add.country,
    //         pincode:add.postcode,
    //         state:add.state
    //     }
    //     )
    //     console.log("add",add)
    // },[])
    // const [shippingaddress, setshippingaddress] = useState({
    //     firstName: '',
    //     lastName: '',
    //     country: 'India',
    //     address: '',
    //     city: '',
    //     state: '',
    //     pincode: '',
    //     phoneNo: '',
    //     email: '',
    // });

    const cartid = localStorage.getItem('randomString')
    // const mycookies = useCookies(['access_token'])
    // const userdata = jwtDecode(mycookies[0].refresh_token)
    // const userid = userdata.user_id

    // console.log(userdata, 'user data console is here')

    // Handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

    };

    // const handleBillingChange = (e) => {
    //     const { name, value } = e.target;
    //     setshippingaddress((prevState) => ({
    //         ...prevState,
    //         [name]: value,
    //     }));
    // };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     console.log('Form Data:', formData);
    // };

    const handleCheckboxChange = (event) => {
        setchecksameadd(event.target.checked);
        // console.log(checksameadd);
    };

    useEffect(() => {
        getcartdetails();
        carttotal();
        fetchState("India")
    }, [])

    // console.log(cartid, 'cart id checkout')

    const getcartdetails = async () => {
        if (userid) {
            axios.get(`${apiurl}/cart-list/${cartid}/${id.user_id}`)
                .then((res) => {
                    console.log(res)
                    setcartdata(res.data)
                    carttotal()
                })
                .catch((err) => {
                    // console.log(err)
                })
        } else {
            axios.get(`${apiurl}/cart-list/${cartid}`)
                .then((res) => {
                    console.log(res)
                    setcartdata(res.data)
                    carttotal()
                })
                .catch((err) => {
                    // console.log(err)
                })
        }
    }

    const carttotal = () => {
        if (!userid) {
            axios.get(`${apiurl}/cart-detail/${cartid}/`)
                .then((res) => {
                    // console.log(res, 'cart total values are here');
                    setcarttotaldata(res.data)
                })
                .catch((err) => {
                    // console.log(err)
                })
        } else {
            axios.get(`${apiurl}/cart-detail/${cartid}/${id.user_id}`)
                .then((res) => {
                    // console.log(res, 'cart total values are here');
                    setcarttotaldata(res.data)
                })
                .catch((err) => {
                    // console.log(err)
                })
        }
    }
    const handleapplycoupon = (oid) => {
        axios.post(`${apiurl}/coupon/`, {
            "order_oid": oid,
            "coupon_code": coupon
        })
            .then((res) => {
                // console.log(res)
                toast.success("Coupon Applied Sucessful")
                setdiscount(res.data.discount_amount)
            })
            .catch((err) => {
                console.log(err)
                toast.error("Coupon is not valid")
            })
        // setcoupon('')

    }
    const applycoupon = async () => {
        if (validateFormData(formData)){
            if (userid) {
                axios.post(`${apiurl}/create-order/`, {
                    "full_name": formData.firstName + formData.lastName,
                    "email": formData.email,
                    "mobile": formData.phoneNo,
                    "billing_address": formData.address,
                    "billing_city": formData.city,
                    "billing_state": formData.state,
                    "billing_country": formData.country,
                    "shipping_address": '',
                    "shipping_city": '',
                    "shipping_state": '',
                    "shipping_country": '',
                    "cart_id": cartid,
                    "user_id": id?.user_id
                })
                    .then((res) => {
                        // console.log(res)
                        // setorderid(res.data.order_oid)
                        // setFormData({
                        //     firstName: '',
                        //     lastName: '',
                        //     country: 'India',
                        //     address: '',
                        //     city: '',
                        //     state: '',
                        //     pincode: '',
                        //     phoneNo: '',
                        //     email: '',
                        // })
                        setO_id(res.data.order_oid)
                        handleapplycoupon(res.data.order_oid)
                        // handlePayment(res.data.order_oid)
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
            else {
                axios.post(`${apiurl}/create-order/`, {
                    "full_name": formData.firstName + formData.lastName,
                    "email": formData.email,
                    "mobile": formData.phoneNo,
                    "billing_address": formData.address,
                    "billing_city": formData.city,
                    "billing_state": formData.state,
                    "billing_country": formData.country,
                    "shipping_address": '',
                    "shipping_city": '',
                    "shipping_state": '',
                    "shipping_country": '',
                    "cart_id": cartid,
                    "user_id": null
                })
                    .then((res) => {
                        // console.log(res)
                        // setorderid(res.data.order_oid)
                        // setFormData({
                        //     firstName: '',
                        //     lastName: '',
                        //     country: 'India',
                        //     address: '',
                        //     city: '',
                        //     state: '',
                        //     pincode: '',
                        //     phoneNo: '',
                        //     email: '',
                        // })
                        setO_id(res.data.order_oid)
                        handleapplycoupon(res.data.order_oid)
                        // handlePayment(res.data.order_oid)
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
            // axios.post(`${apiurl}/coupon/`, {
            //     "cart_id": cartid,
            //     "coupons": coupon
            // })
            //     .then((res) => {
            //         // console.log(res)
            //     })
            //     .catch((err) => {
            //         // console.log(err)
            //         toast.error("Coupon is not valid")
            //     })
            // setcoupon('')

        }
        else {
            toast.error("Please fill out all fields.")
        }

        // console.log(carttotaldata, 'cart total in coupon')
    }

    const handlePayment = async (order_oid) => {
        console.log(order_oid)
        try {
            // Create order on the backend
            const response = await axios.get(`${apiurl}/razorpay-checkout/${order_oid}`);
            const order = response.data;

            console.log(order, 'data response is here')

            const options = {
                key: order.key, // Replace with your Razorpay Key ID
                amount: order.amount,
                currency: order.currency,
                name: 'NSTEE',
                description: 'Test Transaction',
                order_id: order.razorpay_order_id,
                handler: async function (response) {
                    console.log(response, 'checkout data console is here')

                    // Handle successful payment
                    if (response.razorpay_payment_id) {
                        // Optionally send response to your server for further validation
                        console.log(response, 'payment succes response data')
                    } else {
                        // Handle payment failure or cancellation
                        console.error('Payment failed or was canceled');
                        alert('Payment failed or was canceled');
                    }

                    try {
                        // console.log(response.razorpay_payment_id, response.razorpay_order_id, response.razorpay_signature)
                        const verifyResponse = await axios.post(`${apiurl}/payment-success/`, {
                            "razorpay_payment_id": response.razorpay_payment_id,
                            "razorpay_order_id": response.razorpay_order_id,
                            "razorpay_signature": response.razorpay_signature
                        });
                        const verifyResult = verifyResponse.data;
                        console.log(verifyResult)
                        setO_id("")
                        setFormData({
                            firstName: '',
                            lastName: '',
                            country: 'India',
                            address: '',
                            city: '',
                            state: '',
                            pincode: '',
                            phoneNo: '',
                            email: '',
                        })
                        navigate('/thank-you'); // Redirect to the Thank You page
                    } catch (error) {
                        console.error('Payment verification failed', error);
                        alert('Payment verification failed');
                    }
                }
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        } catch (error) {
            console.error('Error fetching checkout data', error);
            alert('Error fetching checkout data');
        }
    };

    const validateFormData = (data) => {
        return (
            data.firstName?.trim() !== '' &&
            data.lastName?.trim() !== '' &&
            data.country?.trim() !== '' &&
            data.address?.trim() !== '' &&
            data.city?.trim() !== '' &&
            data.state?.trim() !== '' &&
            data.pincode?.trim() !== '' &&
            data.phoneNo?.trim() !== '' &&
            data.email?.trim() !== ''
        );
    }

    const checkout = () => {
        if(o_id){
            handlePayment(o_id)
            return
        }
        if (!validateFormData(formData)) {
            alert('Please fill out all fields.');
            return;
        }

        if (userid) {
            axios.post(`${apiurl}/create-order/`, {
                "full_name": formData.firstName + formData.lastName,
                "email": formData.email,
                "mobile": formData.phoneNo,
                "billing_address": formData.address,
                "billing_city": formData.city,
                "billing_state": formData.state,
                "billing_country": formData.country,
                "shipping_address": '',
                "shipping_city": '',
                "shipping_state": '',
                "shipping_country": '',
                "cart_id": cartid,
                "user_id": id?.user_id
            })
                .then((res) => {
                    // console.log(res)
                    setorderid(res.data.order_oid)
                    setFormData({
                        firstName: '',
                        lastName: '',
                        country: 'India',
                        address: '',
                        city: '',
                        state: '',
                        pincode: '',
                        phoneNo: '',
                        email: '',
                    })
                    handlePayment(res.data.order_oid)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        else {
            axios.post(`${apiurl}/create-order/`, {
                "full_name": formData.firstName + formData.lastName,
                "email": formData.email,
                "mobile": formData.phoneNo,
                "billing_address": formData.address,
                "billing_city": formData.city,
                "billing_state": formData.state,
                "billing_country": formData.country,
                "shipping_address": '',
                "shipping_city": '',
                "shipping_state": '',
                "shipping_country": '',
                "cart_id": cartid,
                "user_id": null
            })
                .then((res) => {
                    // console.log(res)
                    setorderid(res.data.order_oid)
                    setFormData({
                        firstName: '',
                        lastName: '',
                        country: 'India',
                        address: '',
                        city: '',
                        state: '',
                        pincode: '',
                        phoneNo: '',
                        email: '',
                    })
                    handlePayment(res.data.order_oid)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const cartdelete = (cartid, itemid) => {
        // console.log('cart and item id is here', cartid, itemid)
        axios.delete(`${apiurl}/cart-delete/${cartid}/${itemid}`)
            .then((res) => {
                // console.log(res)
                getcartdetails()
                props.fetchCartData()
            })
            .catch((err) => {
                // console.log(err)
            })
    }

    const updatecart = (value, quantity) => {
        axios.put(`${apiurl}/update-cart-quantity/`, {
            "product_id": value.product.id,
            "qty": quantity,
            "cart_id": value.cart_id
        })
            .then((res) => {
                getcartdetails();
            })
            .catch((err) => {
                // console.log(err);
            });
    };


    const [quantities, setQuantities] = useState({});

    useEffect(() => {
        if (cartdata) {
            const initialQuantities = cartdata.reduce((acc, item) => {
                acc[item.id] = item.qty;
                return acc;
            }, {});
            setQuantities(initialQuantities);
        }
    }, [cartdata]);

    const handleDecrease = (value) => {
        setQuantities((prevQuantities) => {
            const newQuantities = { ...prevQuantities };
            if (newQuantities[value.id] > 1) {
                newQuantities[value.id] -= 1;
                updatecart(value, newQuantities[value.id]);
            }
            return newQuantities;
        });
    };

    const handleIncrease = (value) => {
        setQuantities((prevQuantities) => {
            const newQuantities = { ...prevQuantities };
            newQuantities[value.id] += 1;
            updatecart(value, newQuantities[value.id]);
            return newQuantities;
        });
    };

    const handleQuantityChange = (id, value) => {
        const parsedValue = parseInt(value, 10);
        if (!isNaN(parsedValue) && parsedValue > 0) {
            setQuantities((prevQuantities) => {
                const newQuantities = { ...prevQuantities, [id]: parsedValue };
                const item = cartdata.find(item => item.id === id);
                if (item) {
                    updatecart(item, parsedValue);
                }
                return newQuantities;
            });
        }
    };

    const handleshipping = (value) => {
        console.log(value, 'shipping values are here')
        if (value === 'prepaid') {
            setshipping('free')
        } else if (value === 'cod') {
            setshipping(`₹ 100`)
        }
    }
    const fetchState = (country) => {
        fetch('https://countriesnow.space/api/v0.1/countries/states', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "country": country,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.data && data.data.states) {
                    setState(data.data.states.map((state) => state.name));
                } else {
                    // console.log(`No states found for ${country}`);
                }
            })
    }
    const fetchcity = (country, state) => {
        fetch('https://countriesnow.space/api/v0.1/countries/state/cities', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "country": country,
                "state": state
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.data) {
                    setcities(data.data)
                    // console.log(`No states found for ${country}`);
                }
            })
    }


    // The 'useEffect' hook is used to execute side effects in function components. In this case, it's used to fetch the user's address based on geolocation when the component mounts (empty dependency array).
    const countries = [
        "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda",
        "Argentina", "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain",
        "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia",
        "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso",
        "Burundi", "Cabo Verde", "Cambodia", "Cameroon", "Canada", "Central African Republic",
        "Chad", "Chile", "China", "Colombia", "Comoros", "Congo", "Costa Rica", "Croatia",
        "Cuba", "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic",
        "East Timor", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea",
        "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland", "France", "Gabon", "Gambia",
        "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea",
        "Guinea-Bissau", "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia",
        "Iran", "Iraq", "Ireland", "Israel", "Italy", "Jamaica", "Japan", "Jordan", "Kazakhstan",
        "Kenya", "Kiribati", "Korea, North", "Korea, South", "Kosovo", "Kuwait", "Kyrgyzstan",
        "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania",
        "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands",
        "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia",
        "Montenegro", "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands",
        "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Macedonia", "Norway", "Oman",
        "Pakistan", "Palau", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines",
        "Poland", "Portugal", "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis",
        "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Sao Tome and Principe",
        "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia",
        "Slovenia", "Solomon Islands", "Somalia", "South Africa", "South Sudan", "Spain",
        "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan",
        "Tanzania", "Thailand", "Timor-Leste", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia",
        "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates",
        "United Kingdom", "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City",
        "Venezuela", "Vietnam", "Yemen", "Zambia", "Zimbabwe"
    ];


    return (
        <>
            <div className='checkout-main'>
                <h4 className='heading-dark text-center text-white mt-3 mb-5'>CheckOut <img src='/assets/images/icons/carttwo.png' className='img-fluid heading-icon' alt='checkout-icon' /></h4>
                <div className='row'>
                    <div className='checkout-form col-sm-7 px-5'>
                        {/* {!userid ? <p className='text-white'>Do you wants to continue as guest or <Link to='/Login' className='text-warning'> login </Link></p> : <p></p>} */}
                        <h4 className='text-white'>Billing Address</h4>
                        <div className='row'>
                            <div className='col-6'>
                                <input type='text' placeholder='First Name' name='firstName' value={formData.firstName} onChange={handleChange}></input>
                            </div>
                            <div className='col-6'>
                                <input type='text' placeholder='Last Name' name='lastName' value={formData.lastName} onChange={handleChange}></input>
                            </div>
                        </div>
                        <input type='text' placeholder='Address' name='address' value={formData.address} onChange={handleChange} />
                        <select className='selectcountry' name='country' value={formData.country} onChange={handleChange} onClick={(e) => { fetchState(e.target.value) }}>
                            {countries.map((country, index) => (
                                <option key={index} value={country}>
                                    {country}
                                </option>
                            ))}
                        </select>
                        <div className='row'>
                            <div className='col-4'>
                                {/* <input type='text' placeholder='State' name='state' value={formData.state} onChange={handleChange} /> */}
                                <select name="state" id="" style={{ color: "white" }} value={formData.state} onChange={handleChange} onClick={(e) => { fetchcity(formData.country, e.target.value) }}>
                                    <option value="none">State</option>
                                    {states.map((state, index) => (
                                        <option key={index} value={state} style={{ backgroundColor: "black" }}>
                                            {state}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-4'>
                                {/* <input type='text' placeholder='City' name='city' value={formData.city} onChange={handleChange} /> */}
                                <select name="city" id="" style={{ color: "white" }} value={formData.city} onChange={handleChange}>
                                    <option value="none">City</option>
                                    {cities.map((city, index) => (
                                        <option key={index} value={city} style={{ backgroundColor: "black" }}>
                                            {city}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-4'>
                                <input type='text' placeholder='Pincode' name='pincode' value={formData.pincode} onChange={handleChange} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                <input type='text' placeholder='Phone No *' name='phoneNo' value={formData.phoneNo} onChange={handleChange} />
                            </div>
                            <div className='col-6'>
                                <input type='text' placeholder='Email' name='email' value={formData.email} onChange={handleChange} />
                            </div>
                        </div>
                        {/* <h4 className='text-white'>Shipping <img src='/assets/images/icons/truck.png' className='img-fluid heading-icon' /> :</h4> */}
                        <div className='shipping-type'>
                            {/* <div className='standard'>
                                <input type='radio' value='prepaid' name='shippingvalue' onClick={(e) => handleshipping(e.target.value)} checked></input>
                                <label for="standard shipping">Fast shipping</label>
                            </div>
                            <div className='fast'>
                                <input type='radio' value='cod' name='shippingvalue' onClick={(e) => handleshipping(e.target.value)}></input>
                                <label for="Fast shipping">COD (Cash On Delivery)</label>
                            </div> */}
                            <input type='button' className='checkout' value='Confirm Order' onClick={checkout} />
                        </div>
                        {/* <div className='billing-checkdiv'>
                            <input type='checkbox' checked={checksameadd} onChange={handleCheckboxChange} />
                            <label for="blling">Billing Address is Same as Shipping Address</label>
                        </div> */}
                        {/* <div className='shipping-info'>
                            <h4>Shipping Address</h4>
                            <div className='row'>
                                <div className='col-6'>
                                    <input type='text' placeholder='First Name'></input>
                                </div>
                                <div className='col-6'>
                                    <input type='text' placeholder='Last Name'></input>
                                </div>
                            </div>
                            <select>
                                <option value="AP">Andhra Pradesh</option>
                            </select>
                            <input type='text' placeholder='Address' />
                            <div className='row'>
                                <div className='col-4'>
                                    <input type='text' placeholder='City' />
                                </div>
                                <div className='col-4'>
                                    <input type='text' placeholder='State' />
                                </div>
                                <div className='col-4'>
                                    <input type='text' placeholder='Pincode' />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <input type='text' placeholder='Phone No *' />
                                </div>
                                <div className='col-6'>
                                    <input type='text' placeholder='Email' />
                                </div>
                                <input type='button' value='submit' onClick={handleSubmit} />
                            </div>
                        </div> */}
                    </div>
                    <div className='col-sm-5 px-5'>
                        <div className='order-info'>
                            <h5>Your Selection:</h5>

                            {
                                cartdata?.map((value, index) => {
                                    return (
                                        <div className='orderinfo-main' key={index}>
                                            <div className='order-info-image'>
                                                <img src={value.product.image} className='img-fluid' />
                                            </div>
                                            <div className='orderinfo-detail'>
                                                <div className='text-end'>
                                                    <i class="fa-solid fa-trash" onClick={() => cartdelete(value.cart_id, value.id)}></i>
                                                </div>
                                                <p>{value.product.description}</p>
                                                <div className='quantity'>
                                                    <span style={{ cursor: "pointer" }} onClick={() => handleDecrease(value)}>-</span>
                                                    <input type='number' value={quantities[value.id] || value.qty} onChange={(e) => handleQuantityChange(value.id, parseInt(e.target.value, 10))}></input>
                                                    <span style={{ cursor: "pointer" }} onClick={() => handleIncrease(value)}>+</span>
                                                </div>
                                                <h6>Quantity : <span>{value.qty}</span></h6>
                                                <h6>Size : <span>{value.size}</span></h6>
                                                <div className="order-info-price">
                                                    <h6>Total: </h6> <h6>₹ {Math.ceil(Number(value.total))}/-</h6>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            <div className='have-coupon'>
                                <h5 className='my-3'>Have a Discount Coupon?</h5>
                                <div className='maincoupondiv'>
                                    <input type='text' value={coupon} onChange={(e) => setcoupon(e.target.value)} placeholder='Add Discount Coupon' />
                                    <input type='button' value='Add Coupon' onClick={applycoupon} />
                                    <ToastContainer />
                                </div>
                            </div>

                        </div>
                        <div className='carttotal-price'>
                            <div>
                                <h5>Sub Total:</h5> <h5>₹ {Math.ceil(Number(carttotaldata?.total))}/-</h5>
                            </div>
                            {discount !== "" && discount !== undefined && (
                                <div>
                                    <h6>Discount:</h6>
                                    <h6>₹ {Math.ceil(Number(discount))}/-</h6>
                                </div>
                            )}
                            {/* <div>
                                <h5>Tax:</h5> <h5>₹ {carttotaldata?.sub_total}/-</h5>
                            </div> */}
                            <div>
                                {/* <h6>Shipping</h6> <h6>{shipping}/-</h6> */}
                            </div>
                            {/* <div>
                                <h6>Discount Applied</h6> <h6> ₹ 1,199/-</h6>
                            </div> */}
                            <div className='carttotal'>
                                <h5>Total:</h5> <h5>₹ {Math.ceil(Number(carttotaldata?.total)-Math.ceil(Number(discount)))}/-</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
