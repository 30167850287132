import React, { useEffect, useState, Suspense } from 'react';
import './home.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from 'aos';
import "aos/dist/aos.css";
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import loadable from '@loadable/component';

// Apply lazy loading
//const CenterMode = React.lazy(() => import('../../comcomponents/slickslider'));
//const Subscribe = React.lazy(() => import('../../comcomponents/subscribe'));
//const Hometry = React.lazy(() => import('./hometry'));
//const Firstslidermobile = React.lazy(() => import('./firstslidermobile'));

const CenterMode = loadable(() => import('../../comcomponents/slickslider'));
const Subscribe = loadable(() => import('../../comcomponents/subscribe'));
const Hometry = loadable(() => import('./hometry'));
const Firstslidermobile = loadable(() => import('./firstslidermobile'));

export default function Home() {

  const [showofferspopup, setshowofferspopup] = useState(false);
  const handleofferspopupClose = () => setshowofferspopup(false);
  const handleofferspopupShow = () => setshowofferspopup(true);

  useEffect(() => {
    AOS.init();
    // handleofferspopupShow()
  }, [])

  var settings2 = {
    slidesToShow: 1,
    speed: 1500, // Increased speed for smoother transition
    cssEase: 'ease-in-out', // Smoother easing function
    arrows: false,
    dots: false,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      {/* <Headermain /> */}
      <div className='home-header'>

      </div>
      {/* <Homeproductbanner /> */}
      <div className='home-product-show'>
        <h1 className='heading-text-shadow text-center py-5 mx-auto'>Crew Neck Supima</h1>
        {/* <Homeproductshow /> */}
        <div className='row secondsec-desktop'>
          <div className='col-sm-4'>
            <div className='home-productimage-div'>
              <img src='/assets/images/productimages/home/productshowone.webp' loading="lazy"/>
            </div>
            <div className='home-productinfo'>
              <h2>More soft, More Comfy</h2>
              <p>A comfort that feels like home.</p>
            </div>
          </div>
          <div className='col-sm-4'>
            <div className='home-productimage-div'>
              <img src='/assets/images/productimages/home/productshowtwo.webp' loading="lazy"/>
            </div>
            <div className='home-productinfo'>
              <h2>Invisible Stitching</h2>
              <p>Come with fine invisible stitching to provide you with the best comfort</p>
            </div>
          </div>
          <div className='col-sm-4'>
            <div className='home-productimage-div'>
              <img src='/assets/images/productimages/home/productshowthree.webp' loading="lazy"/>
            </div>
            <div className='home-productinfo'>
              <h2>Durable Rib Collar</h2>
              <p>Rib Collar that never loses it's comfiness.</p>
            </div>
          </div>
        </div>

        <Firstslidermobile />
      </div>

      <Hometry />

      <div className='home-product-infosec'>
        <div className='row'>
          <div className='col-4'>
            <div className='animationimage-circulerthree'>
              <img src='/assets/images/productimages/home/Circle 3.webp' loading="lazy" className='img-fluid home-product-infosec-smallimagethree' data-aos="zoom-in" data-aos-offset="0" data-aos-easing="ease-in-sine" data-aos-duration="1000" />
              <div className='three' data-aos="zoom-in" data-aos-offset="0" data-aos-easing="ease-in-sine" data-aos-duration="1000"></div>
            </div>
            <div className='animationimage-circuler'>
              <img src='/assets/images/productimages/home/Circle 1.webp' loading="lazy" className='img-fluid home-product-infosec-smallimageone' data-aos="zoom-in" data-aos-offset="0" data-aos-easing="ease-in-sine" data-aos-duration="1000" />
              <div className='one' data-aos="zoom-in" data-aos-offset="0" data-aos-easing="ease-in-sine" data-aos-duration="1000" ></div>
            </div>
          </div>
          <div className='col-4'>
            <img src='/assets/images/productimages/home/cutout image png.webp' loading="lazy" className='img-fluid home-product-infosec-leftimage' data-aos="fade-right" data-aos-offset="0" data-aos-easing="ease-in-sine" data-aos-duration="1000" />
          </div>
          <div className='col-4'>
            <div className='animationimage-circulertwo'>
              <img src='/assets/images/productimages/home/Circle 2.webp' loading="lazy" className='img-fluid home-product-infosec-smallimagetwo' data-aos="zoom-in" data-aos-offset="0" data-aos-easing="ease-in-sine" data-aos-duration="1000" />
              <div className='two' data-aos="zoom-in" data-aos-offset="0" data-aos-easing="ease-in-sine" data-aos-duration="1000"></div>
            </div>
          </div>
        </div>
      </div>


      <div className='home-clientpost'>
        <CenterMode />
        <div className='text-center py-5'>
          {/* <Link to='/client-posts'>
            <button className='btn postyours'>Post Yours</button>
          </Link> */}
        </div>
        {/* <div className='home-about-fabric text-center'>
          <img src='/assets/images/productimages/home/tshirt.png' className='img-fluid'></img>
          <div className='text-center'>
            <button className='btn postyours'>fabric Info</button>
          </div>
          <h2 className='heading-dark py-5 m-0 text-white'>Comfort Anytime</h2>
        </div> */}
      </div>
      <div className='home-shop-know'>
        <div className='row'>
          <div className='col-6 p-0'>
            <img src='/assets/images/productimages/home/back 1.webp' className='img-fluid' loading="lazy" />
          </div>
          <div className='col-6 p-0'>
            <img src='/assets/images/productimages/home/maroon 1.webp' className='img-fluid' loading="lazy" />
          </div>
        </div>
        <div className='row'>
          <div className='col-6 p-0'>
            <img src='/assets/images/productimages/home/off white 1.webp' className='img-fluid' loading="lazy" />
          </div>
          <div className='col-6 p-0'>
            <img src='/assets/images/productimages/home/green 1.webp' className='img-fluid' loading="lazy" />
          </div>
        </div>
        <div className='shopbutton'>
          <Link to='/shop'>
            <button class="btn shopnow">Shop Now</button>
          </Link>
        </div>
      </div>


      <Subscribe />
      {/* User profile edit Modal */}
      <Modal className='profileedit-model' show={showofferspopup} onHide={handleofferspopupClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Your Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-12'>
              <h4>working model</h4>
            </div>
          </div>
          <ToastContainer />
        </Modal.Body>
      </Modal>
    </>
  )
}
