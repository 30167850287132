import React from 'react';
import './login.css'
import Loginform from '../../../comcomponents/loginform';

export default function Login() {
  return (
    <div className='login-main'>
        <Loginform />
    </div>
  )
}
