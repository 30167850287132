import React from 'react';

function CartID() {
    // Function to generate a random string with the desired length
    const generateRandomString = () => {
        const length = 30; // Desired length of the random string
        const characters = 123456789; // Characters to choose from
        let randomString = '';
        // Store the generated 'randomString' in localStorage for later use
        const randomNumber = Math.random();
        randomString = randomNumber.toString().slice(2);
        console.log("random1",randomString)
        localStorage.setItem('randomString', randomString);
        return randomString
    };

    // Function to check if the random string exists in localStorage
    const existingRandomString = localStorage.getItem('randomString');

    if (!existingRandomString) {
        // Random string doesn't exist in localStorage, generate and add it
        localStorage.getItem('randomString', generateRandomString())
        // generateRandomString();
    } else {
        // Log the existing 'randomString' found in localStorage
        // console.log(`Random string in localStorage: ${existingRandomString}`);
    }
}

export default CartID;
